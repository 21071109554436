<template>
    <div class="main">
        <manager-sub-title>통계표</manager-sub-title>
        <div class="search">
            <partner-selector-comp @setPartnerId="setPartnerId"></partner-selector-comp>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-select v-model="smonth" size="mini" placeholder="가입월 선택" style="margin-left: 5px">
                <el-option
                        v-for="item in months"
                        :key="item.key"
                        :label="item.key"
                        :value="item.val">
                </el-option>
            </el-select>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색</el-button>
        </div>
        <div class="data">
            <table class="table100">
                <tr>
                    <td colspan="5" style="font-weight: bold;font-size: 15px;background-color: skyblue">
                        전체통계표
                        <div v-if="startTime!='' && endTime!=''" style="font-size: 10px;font-weight: normal">
                            ({{startTime +' ~ '+endTime}})
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>신규가입/방문</th>
                    <th>입금</th>
                    <th>출금(회원)</th>
                    <th>출금(파트너)</th>
                    <th>손이익</th>
                </tr>
                <tr>
                    <td>{{totalUser +'명'}} / {{totalLoginUserCount +'명'}}</td>
                    <td>{{totalRecharge|comma}}</td>
                    <td>{{totalExchange|comma}}</td>
                    <td>{{totalExchangePartner|comma}}</td>
                    <td class="text-green text-blod">{{(totalREProfit)|comma}}</td>
                </tr>
                <tr>
                    <th>첫충누적Point</th>
                    <th>매충누적Point</th>
                    <th>베팅낙첨Point</th>
                    <th>베팅낙첨2추천인Point</th>
                    <th>총누적Point</th>
                </tr>
                <tr>
                    <td>{{(totalAccumulateFirstRechargeBonusPoint)|comma}}</td>
                    <td>{{(totalAccumulateDayRechargeBonusPoint )|comma}}</td>
                    <td>{{(totalAccumulateBetFailPoint )|comma}}</td>
                    <td>{{(totalAccumulateBetFail2RefPoint )|comma}}</td>
                    <td class="text-green text-blod">
                        {{(fishRechargCashTotal)|comma}}
                    </td>
                </tr>
                <tr>
                    <th>Sports배팅건수</th>
                    <th>Sports베팅금액</th>
                    <th>Sports당첨금액</th>
                    <th>Sports취소금액</th>
                    <th>Sports베팅손익</th>
                </tr>
                <tr>
                    <td>{{(totalBetCount) + '건'}}</td>
                    <td>{{(totalBetAmount)|comma}}</td>
                    <td>{{(totalWinAmount)|comma}}</td>
                    <td>{{(totalBetCancelAmount)|comma}}</td>
                    <td class="text-green text-blod">
                        {{(totalBetAmount-totalWinAmount-totalBetCancelAmount)|comma}}
                    </td>
                </tr>
                <tr>
                    <th>Inplay배팅건수</th>
                    <th>Inplay베팅금액</th>
                    <th>Inplay당첨금액</th>
                    <th>Inplay취소금액</th>
                    <th>Inplay베팅손익</th>
                </tr>
                <tr>
                    <td>{{(totalInplayBetCount) + '건'}}</td>
                    <td>{{(totalInplayBetAmount)|comma}}</td>
                    <td>{{(totalInplayWinAmount)|comma}}</td>
                    <td>{{(totalInplayBetCancelAmount)|comma}}</td>
                    <td class="text-green text-blod">
                        {{(totalInplayBetAmount-totalInplayWinAmount-totalInplayBetCancelAmount)|comma}}
                    </td>
                </tr>
                <tr>
                    <th>MiniGame배팅건수</th>
                    <th>MiniGame베팅금액</th>
                    <th>MiniGame당첨금액</th>
                    <th>MiniGame취소금액</th>
                    <th>MiniGame베팅손익</th>
                </tr>
                <tr>
                    <td>{{(totalLBetCount ) + '건'}}</td>
                    <td>{{(totalLBetAmount )|comma}}</td>
                    <td>{{(totalLWinAmount)|comma}}</td>
                    <td>{{(totalLCancelAmount+totalLSpecialAmount)|comma}}</td>
                    <td class="text-green text-blod">
                        {{(totalLBetAmount-totalLWinAmount-totalLCancelAmount-totalLSpecialAmount)|comma}}
                    </td>
                </tr>

                <tr>
                    <th>HonorCasino배팅건수</th>
                    <th>HonorCasino베팅금액</th>
                    <th>HonorCasino당첨금액</th>
                    <th>HonorCasino취소금액</th>
                    <th>HonorCasino베팅손익</th>
                </tr>
                <tr>
                    <td>{{(honorCasinoBetCountTotal)|comma}}</td>
                    <td>{{(honorCasinoBetCashTotal)|comma}}</td>
                    <td>{{(honorCasinowinCashTotal)|comma}}</td>
                    <td>-</td>
                    <td class="text-green text-blod">{{(honorCasinoBetCashTotal-honorCasinowinCashTotal)|comma}}</td>
                </tr>
                <tr>
                    <th>HonorSlot배팅건수</th>
                    <th>HonorSlot베팅금액</th>
                    <th>HonorSlot당첨금액</th>
                    <th>HonorSlot취소금액</th>
                    <th>HonorSlot베팅손익</th>
                </tr>
                <tr>
                    <td>{{(honorSlotBetCountTotal)|comma}}</td>
                    <td>{{(honorSlotBetCashTotal)|comma}}</td>
                    <td>{{(honorSlotwinCashTotal)|comma}}</td>
                    <td>-</td>
                    <td class="text-green text-blod">{{(honorSlotBetCashTotal-honorSlotwinCashTotal)|comma}}</td>
                </tr>
                
<!--                <tr>-->
<!--                    <th>ReachCasino배팅건수</th>-->
<!--                    <th>ReachCasino베팅금액</th>-->
<!--                    <th>ReachCasino당첨금액</th>-->
<!--                    <th>ReachCasino취소금액</th>-->
<!--                    <th>ReachCasino베팅손익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{(reachCasinoBetCountTotal)|comma}}</td>-->
<!--                    <td>{{(reachCasinoBetCashTotal)|comma}}</td>-->
<!--                    <td>{{(reachCasinowinCashTotal)|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green text-blod">{{(reachCasinoBetCashTotal-reachCasinowinCashTotal)|comma}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <th>ReachSlot배팅건수</th>-->
<!--                    <th>ReachSlot베팅금액</th>-->
<!--                    <th>ReachSlot당첨금액</th>-->
<!--                    <th>ReachSlot취소금액</th>-->
<!--                    <th>ReachSlot베팅손익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{(reachSlotBetCountTotal)|comma}}</td>-->
<!--                    <td>{{(reachSlotBetCashTotal)|comma}}</td>-->
<!--                    <td>{{(reachSlotwinCashTotal)|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green text-blod">{{(reachSlotBetCashTotal-reachSlotwinCashTotal)|comma}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <th>TgameCasino배팅건수</th>-->
<!--                    <th>TgameCasino베팅금액</th>-->
<!--                    <th>TgameCasino당첨금액</th>-->
<!--                    <th>TgameCasino취소금액</th>-->
<!--                    <th>TgameCasino베팅손익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{(casinoBetCountTotal)|comma}}</td>-->
<!--                    <td>{{(casinoBetCashTotal)|comma}}</td>-->
<!--                    <td>{{(casinowinCashTotal)|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green text-blod">{{(casinoBetCashTotal-casinowinCashTotal)|comma}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <th>TgameSlot배팅건수</th>-->
<!--                    <th>TgameSlot베팅금액</th>-->
<!--                    <th>TgameSlot당첨금액</th>-->
<!--                    <th>TgameSlot취소금액</th>-->
<!--                    <th>TgameSlot베팅손익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{(slotBetCountTotal )|comma}}</td>-->
<!--                    <td>{{(slotBetCashTotal )|comma}}</td>-->
<!--                    <td>{{(slotwinCashTotal )|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green text-blod">{{(slotBetCashTotal-slotwinCashTotal)|comma}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <th>분리형 Casino입금</th>-->
<!--                    <th>분리형 Casino출금</th>-->
<!--                    <th></th>-->
<!--                    <th></th>-->
<!--                    <th>분리형 Casino손이익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{totalCasinoRecharge|comma}}</td>-->
<!--                    <td>{{totalCasinoExchange|comma}}</td>-->
<!--                    <td></td>-->
<!--                    <td></td>-->
<!--                    <td class="text-green text-blod">{{(totalCasinoREProfit)|comma}}</td>-->
<!--                </tr>-->
                <tr>
                    <th>수동지급 머니</th>
                    <th>수동지급 Point</th>
                    <th></th>
                    <th></th>
                    <th>파트너정산(입-출 * %)</th>
                </tr>
                <tr>
                    <td class="text-green text-blod">{{totalAccumulateManagerAddCash|comma}}</td>
                    <td class="text-green text-blod">{{totalAccumulateManagerAddPoint|comma}}</td>
                    <td></td>
                    <td></td>
                    <td class="text-green text-blod">
                        <span v-if="agent.id != null">{{totalPatnerStAmount|comma}}</span>
                        <span v-else>0</span>
                    </td>
                </tr>
            </table>
            <table class="table50" v-for="item in sdata">
                <tr>
                    <td colspan="5" style="font-weight: bold;font-size: 15px">
                        {{item.date|datef('yyyy-MM-DD')}}
                    </td>
                </tr>
                <tr>
                    <th>신규가입/방문자</th>
                    <th>입금</th>
                    <th>출금</th>
                    <th>출금(파트너)</th>
                    <th>손이익</th>
                </tr>
                <tr>
                    <td>{{item.siteMemberCount +'명('+item.realBeter+')'}} / {{item.loginUsercount +'명'}}</td>
                    <td>{{item.rechargeTotal|comma}}</td>
                    <td>{{item.exchangeTotal|comma}}</td>
                    <td>{{item.exchangePartnerTotal|comma}}</td>
                    <td class="text-green">{{(item.rechargeTotal - item.exchangeTotal - item.exchangePartnerTotal)|comma}}</td>
                </tr>
                <tr>
                    <th>첫충누적Point</th>
                    <th>매충누적Point</th>
                    <th>베팅낙첨Point</th>
                    <th>베팅낙첨2추천인Point</th>
                    <th>총누적Point</th>
                </tr>
                <tr>
                    <td>{{(item.accumulateFirstRechargePoint)|comma}}</td>
                    <td>{{(item.accumulateDayRechargePoint )|comma}}</td>
                    <td>{{(item.accumulateBetFailPoint )|comma}}</td>
                    <td>{{(item.accumulateBetFail2RefPoint )|comma}}</td>
                    <td class="text-green">
                        {{(item.accumulateFirstRechargePoint+item.accumulateDayRechargePoint+item.accumulateBetFailPoint+item.accumulateBetFail2RefPoint)|comma}}
                    </td>
                </tr>
                <tr>
                    <th>Sports배팅건수</th>
                    <th>Sports베팅금액</th>
                    <th>Sports당첨금액</th>
                    <th>Sports취소금액</th>
                    <th>Sports베팅손익</th>
                </tr>
                <tr>
                    <td>{{(item.sportsBetCount) + '건'}}</td>
                    <td>{{(item.sportsBetCashTotal)|comma}}</td>
                    <td>{{(item.sportsBetWinCashTotal)|comma}}</td>
                    <td>{{(item.sportsBetCancelCashTotal)|comma}}</td>
                    <td class="text-green">
                        {{(item.sportsBetCashTotal-(item.sportsBetSpecialCashTotal+item.sportsBetCancelCashTotal+item.sportsBetWinCashTotal))|comma}}
                    </td>
                </tr>
                <tr>
                    <th>Inplay배팅건수</th>
                    <th>Inplay베팅금액</th>
                    <th>Inplay당첨금액</th>
                    <th>Inplay취소금액</th>
                    <th>Inplay베팅손익</th>
                </tr>
                <tr>
                    <td>{{(item.inplayBetCount) + '건'}}</td>
                    <td>{{(item.inplayBetCashTotal)|comma}}</td>
                    <td>{{(item.inplayBetWinCashTotal)|comma}}</td>
                    <td>{{(item.inplayBetCancelCashTotal)|comma}}</td>
                    <td class="text-green">
                        {{(item.inplayBetCashTotal-(item.inplayBetSpecialCashTotal+item.inplayBetCancelCashTotal+item.inplayBetWinCashTotal))|comma}}
                    </td>
                </tr>
                <tr>
                    <th>MiniGame배팅건수</th>
                    <th>MiniGame베팅금액</th>
                    <th>MiniGame당첨금액</th>
                    <th>MiniGame취소금액</th>
                    <th>MiniGame베팅손익</th>
                </tr>
                <tr>
                    <td>{{(item.leisureBetCount ) + '건'}}</td>
                    <td>{{(item.leisureBetCashTotal )|comma}}</td>
                    <td>{{(item.leisureBetWinCashTotal )|comma}}</td>
                    <td>{{(item.leisureBetSpecialCashTotal+item.leisureBetCancelCashTotal)|comma}}</td>
                    <td class="text-green">
                        {{(item.leisureBetCashTotal-(item.leisureBetWinCashTotal+item.leisureBetSpecialCashTotal+item.leisureBetCancelCashTotal)
                        )|comma}}
                    </td>
                </tr>

                <tr>
                    <th>HonorCasino배팅건수</th>
                    <th>HonorCasino베팅금액</th>
                    <th>HonorCasino당첨금액</th>
                    <th>HonorCasino취소금액</th>
                    <th>HonorCasino베팅손익</th>
                </tr>
                <tr>
                    <td>{{(item.honorCasinoBetCountTotal )|comma}}</td>
                    <td>{{(item.honorCasinoBetCashTotal )|comma}}</td>
                    <td>{{(item.honorCasinoWinCashTotal )|comma}}</td>
                    <td>-</td>
                    <td class="text-green">{{(item.honorCasinoBetCashTotal-item.honorCasinoWinCashTotal)|comma}}</td>
                </tr>
                <tr>
                    <th>HonorSlot배팅건수</th>
                    <th>HonorSlot베팅금액</th>
                    <th>HonorSlot당첨금액</th>
                    <th>HonorSlot취소금액</th>
                    <th>HonorSlot베팅손익</th>
                </tr>
                <tr>
                    <td>{{(item.honorSlotBetCountTotal )|comma}}</td>
                    <td>{{(item.honorSlotBetCashTotal )|comma}}</td>
                    <td>{{(item.honorSlotWinCashTotal )|comma}}</td>
                    <td>-</td>
                    <td class="text-green">{{(item.honorSlotBetCashTotal-item.honorSlotWinCashTotal)|comma}}</td>
                </tr>


<!--                <tr>-->
<!--                    <th>ReachCasino배팅건수</th>-->
<!--                    <th>ReachCasino베팅금액</th>-->
<!--                    <th>ReachCasino당첨금액</th>-->
<!--                    <th>ReachCasino취소금액</th>-->
<!--                    <th>ReachCasino베팅손익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{(item.reachCasinoBetCountTotal )|comma}}</td>-->
<!--                    <td>{{(item.reachCasinoBetCashTotal )|comma}}</td>-->
<!--                    <td>{{(item.reachCasinoWinCashTotal )|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green">{{(item.reachCasinoBetCashTotal-item.reachCasinoWinCashTotal)|comma}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <th>ReachSlot배팅건수</th>-->
<!--                    <th>ReachSlot베팅금액</th>-->
<!--                    <th>ReachSlot당첨금액</th>-->
<!--                    <th>ReachSlot취소금액</th>-->
<!--                    <th>ReachSlot베팅손익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{(item.reachSlotBetCountTotal )|comma}}</td>-->
<!--                    <td>{{(item.reachSlotBetCashTotal )|comma}}</td>-->
<!--                    <td>{{(item.reachSlotWinCashTotal )|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green">{{(item.reachSlotBetCashTotal-item.reachSlotWinCashTotal)|comma}}</td>-->
<!--                </tr>-->

<!--                <tr>-->
<!--                    <th>TgameCasino배팅건수</th>-->
<!--                    <th>TgameCasino베팅금액</th>-->
<!--                    <th>TgameCasino당첨금액</th>-->
<!--                    <th>TgameCasino취소금액</th>-->
<!--                    <th>TgameCasino베팅손익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{(item.casinoBetCountTotal )|comma}}</td>-->
<!--                    <td>{{(item.casinoBetCashTotal )|comma}}</td>-->
<!--                    <td>{{(item.casinowinCashTotal )|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green">{{(item.casinoBetCashTotal-item.casinowinCashTotal)|comma}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <th>TgameSlot배팅건수</th>-->
<!--                    <th>TgameSlot베팅금액</th>-->
<!--                    <th>TgameSlot당첨금액</th>-->
<!--                    <th>TgameSlot취소금액</th>-->
<!--                    <th>TgameSlot베팅손익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{(item.slotBetCountTotal )|comma}}</td>-->
<!--                    <td>{{(item.slotBetCashTotal )|comma}}</td>-->
<!--                    <td>{{(item.slotwinCashTotal )|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green">{{(item.slotBetCashTotal-item.slotwinCashTotal)|comma}}</td>-->
<!--                </tr>-->

<!--                <tr>-->
<!--                    <th>분리형 Casino입금</th>-->
<!--                    <th>분리형 Casino출금</th>-->
<!--                    <th>-</th>-->
<!--                    <th>-</th>-->
<!--                    <th>분리형 Casino손이익</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>{{item.casinoRechargeTotal|comma}}</td>-->
<!--                    <td>{{item.casinoExchangeTotal|comma}}</td>-->
<!--                    <td>-</td>-->
<!--                    <td>-</td>-->
<!--                    <td class="text-green">{{(item.casinoRechargeTotal - item.casinoExchangeTotal)|comma}}</td>-->
<!--                </tr>-->
                
                <tr>
                    <th>수동지급 머니</th>
                    <th>수동지금 Point</th>
                    <th>-</th>
                    <th>-</th>
                    <th>파트너정산(입-출 * %)</th>
                </tr>
                <tr>
                    <td class="text-green">{{item.accumulateManagerAddCash|comma}}</td>
                    <td class="text-green">{{item.accumulateManagerAddPoint|comma}}</td>
                    <td>-</td>
                    <td>-</td>
                    <td class="text-green">
                        <span v-if="agent.id != null">{{item.partnerStatisticAmount|comma}}</span>
                        <span v-else>0</span>
                    </td>
                </tr>

            </table>
        </div>
    </div>
</template>

<script>

    import {Loading} from 'element-ui';
    import {getMainStatistic} from "../../network/manager/statisticRequest";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import {manager} from "../../common/administrator/managerMixin";
    import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import ManagerSubTitle from "../../components/administrator/ManagerSubTitle";

    export default {
        name: "MainStatistic",
        components: {
            ManagerSubTitle,
            DateSelectorComp, PartnerSelectorComp, OnlineUserComp, TodayStatisticComp, ManagerTopbarComp
        },
        mixins: [manager],
        data() {
            return {
                agent: {id: null},
                sdata: [],
                months: [
                    {'key': '1월', 'val': '1'}, {'key': '2월', 'val': '2'}, {'key': '3월', 'val': '3'}, {
                        'key': '4월',
                        'val': '4'
                    }, {'key': '5월', 'val': '5'}, {'key': '6월', 'val': '6'},
                    {'key': '7월', 'val': '7'}, {'key': '8월', 'val': '8'}, {'key': '9월', 'val': '9'}, {
                        'key': '10월',
                        'val': '10'
                    }, {'key': '11월', 'val': '11'}, {'key': '12월', 'val': '12'}
                ],
                totalUser: 0,
                totalRecharge: 0,
                totalExchange: 0,
                totalExchangePartner: 0,
                totalCasinoRecharge: 0,
                totalCasinoExchange: 0,
                totalAccumulatePoints: 0,
                totalBetCount: 0,
                totalBetCancelAmount: 0,
                totalBetSpecialAmount: 0,
                totalBetAmount: 0,
                totalWinAmount: 0,
                totalInplayWinAmount: 0,
                totalInplayBetCancelAmount: 0,
                totalInplayBetAmount: 0,
                totalLBetCount: 0,
                totalInplayBetCount: 0,
                totalLBetAmount: 0,
                totalLWinAmount: 0,
                totalREProfit: 0,
                totalCasinoREProfit: 0,
                totalBetProfit: 0,
                totalPatnerStAmount: 0,
                totalWinPatnerStAmount: 0,
                totalPartnerBetStatisticAmount: 0,
                totalPartnerBetWinStatisticAmount: 0,
                totalPartnerLBetStatisticAmount: 0,
                totalPartnerLBetWinStatisticAmount: 0,
                totalLoginUserCount: 0,
                totalAccumulateManagerAddCash: 0,
                totalAccumulateManagerAddPoint: 0,
                totalAccumulateFirstRechargeBonusPoint: 0,
                totalAccumulateDayRechargeBonusPoint: 0,
                totalAccumulateBetFailPoint: 0,
                totalAccumulateBetFail2RefPoint: 0,
                totalLCancelAmount: 0,
                totalLSpecialAmount: 0,

                casinoBetCountTotal: 0,
                casinoBetCashTotal: 0,
                casinowinCashTotal: 0,
                casinocancelCashTotal: 0,
                slotBetCountTotal: 0,
                slotBetCashTotal: 0,
                slotwinCashTotal: 0,
                slotcancelCashTotal: 0,
                fishRechargCashTotal: 0,
                fishExchangeCashTotal: 0,

                reachCasinoBetCountTotal: 0,
                reachCasinoBetCashTotal: 0,
                reachCasinowinCashTotal: 0,
                reachSlotBetCountTotal: 0,
                reachSlotBetCashTotal: 0,
                reachSlotwinCashTotal: 0,

                honorCasinoBetCountTotal: 0,
                honorCasinoBetCashTotal: 0,
                honorCasinowinCashTotal: 0,
                honorSlotBetCountTotal: 0,
                honorSlotBetCashTotal: 0,
                honorSlotwinCashTotal: 0,


            }
        }
        ,
        methods: {
            getMainStatistic() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getMainStatistic(this.agent, this.startTime, this.endTime, this.smonth).then(res => {
                    this.sdata = res.data.data
                    this.setTotal(this.sdata)
                    loadingInstance.close();
                })
            },
            search() {
                this.getMainStatistic()
            },
            setPartnerId(partnerId) {
                this.agent.id = partnerId;
            },
            setStartDate(date) {
                this.startTime = date
            },
            setEndDate(date) {
                this.endTime = date
            },
            setTotal(list) {
                let totalRecharge = 0;
                let totalExchange = 0;
                let totalExchangePartner = 0;
                let totalCasinoRecharge = 0;
                let totalCasinoExchange = 0;
                let totalUser = 0;
                let totalBetCount = 0;

                let totalBetAmount = 0;

                let totalWinAmount = 0;
                let totalBetCancelAmount = 0;
                let totalBetSpecialAmount = 0;

                let totalLBetCount = 0;
                let totalLBetAmount = 0;
                let totalLWinAmount = 0;
                let totalLLoseAmount = 0;
                let totalLCancelAmount = 0;
                let totalLSpecialAmount = 0;
                let totalInplayWinAmount = 0;
                let totalInplayBetCount = 0;
                let totalInplayBetAmount = 0;
                let totalInplayBetCancelAmount = 0;
                let totalAccumulatePoints = 0;
                let totalAccumulateManagerAddCash = 0;
                let totalAccumulateManagerAddPoint = 0;
                let totalAccumulateFirstRechargeBonusPoint = 0;
                let totalAccumulateDayRechargeBonusPoint = 0;
                let totalAccumulateBetFailPoint = 0;
                let totalAccumulateBetFail2RefPoint = 0;
                let totalPatnerStAmount = 0;
                let totalWinPatnerStAmount = 0;
                let totalPartnerBetStatisticAmount = 0;
                let totalPartnerBetWinStatisticAmount = 0;
                let totalPartnerLBetStatisticAmount = 0;
                let totalPartnerLBetWinStatisticAmount = 0;
                let totalLoginUserCount = 0;

                let casinoBetCountTotal = 0;
                let casinoBetCashTotal = 0;
                let casinowinCashTotal = 0;
                let casinocancelCashTotal = 0;
                let slotBetCountTotal = 0;
                let slotBetCashTotal = 0;
                let slotwinCashTotal = 0;
                let slotcancelCashTotal = 0;
                let fishRechargCashTotal = 0;
                let fishExchangeCashTotal = 0;

                let reachCasinoBetCountTotal = 0;
                let reachCasinoBetCashTotal = 0;
                let reachCasinowinCashTotal = 0;
                let reachSlotBetCountTotal = 0;
                let reachSlotBetCashTotal = 0;
                let reachSlotwinCashTotal = 0;

                let honorCasinoBetCountTotal = 0;
                let honorCasinoBetCashTotal = 0;
                let honorCasinowinCashTotal = 0;
                let honorSlotBetCountTotal = 0;
                let honorSlotBetCashTotal = 0;
                let honorSlotwinCashTotal = 0;

                //베팅손익금
                let totalBetWinAmount = 0;

                list.map(s => {
                    totalRecharge += s.rechargeTotal;
                    totalExchange += s.exchangeTotal;
                    totalExchangePartner += s.exchangePartnerTotal;
                    totalCasinoRecharge += s.casinoRechargeTotal;
                    totalCasinoExchange += s.casinoExchangeTotal;

                    totalAccumulatePoints += s.accumulatePoints;
                    totalAccumulateManagerAddCash += s.accumulateManagerAddCash;
                    totalAccumulateManagerAddPoint += s.accumulateManagerAddPoint;
                    totalAccumulateFirstRechargeBonusPoint += s.accumulateFirstRechargePoint;
                    totalAccumulateDayRechargeBonusPoint += s.accumulateDayRechargePoint;
                    totalAccumulateBetFailPoint += s.accumulateBetFailPoint;
                    totalAccumulateBetFail2RefPoint += s.accumulateBetFail2RefPoint;

                    totalUser += s.siteMemberCount;
                    totalBetCount += (s.sportsBetCount);
                    totalInplayBetCount += (s.inplayBetCount);
                    totalInplayBetAmount += (s.inplayBetCashTotal);
                    totalBetAmount += (s.sportsBetCashTotal);
                    totalWinAmount += (s.sportsBetWinCashTotal);
                    totalBetCancelAmount += (s.sportsBetCancelCashTotal);
                    totalBetSpecialAmount += (s.sportsBetSpecialCashTotal);
                    totalInplayWinAmount += (s.inplayBetWinCashTotal);
                    totalInplayBetCancelAmount += (s.inplayBetCancelCashTotal);

                    totalLBetCount += s.leisureBetCount;
                    totalLBetAmount += s.leisureBetCashTotal;
                    totalLWinAmount += s.leisureBetWinCashTotal;
                    totalLCancelAmount += s.leisureBetCancelCashTotal;
                    totalLSpecialAmount += s.leisureBetSpecialCashTotal;

                    totalPatnerStAmount += s.partnerStatisticAmount;
                    totalWinPatnerStAmount += s.partnerWinStatisticAmount;
                    totalPartnerBetStatisticAmount += s.partnerBetStatisticAmount;
                    totalPartnerBetWinStatisticAmount += s.partnerBetWinStatisticAmount;

                    totalPartnerLBetStatisticAmount += s.partnerLBetStatisticAmount;
                    totalPartnerLBetWinStatisticAmount += s.partnerLBetWinStatisticAmount;

                    totalLoginUserCount += s.loginUsercount;

                    casinoBetCountTotal += s.casinoBetCountTotal;
                    casinoBetCashTotal += s.casinoBetCashTotal;
                    casinowinCashTotal += s.casinowinCashTotal;
                    casinocancelCashTotal += s.casinocancelCashTotal;
                    slotBetCountTotal += s.slotBetCountTotal;
                    slotBetCashTotal += s.slotBetCashTotal;
                    slotwinCashTotal += s.slotwinCashTotal;
                    slotcancelCashTotal += s.slotcancelCashTotal;
                    fishRechargCashTotal += s.fishRechargCashTotal;
                    fishExchangeCashTotal += s.fishExchangeCashTotal;


                     reachCasinoBetCountTotal += s.reachCasinoBetCountTotal;
                     reachCasinoBetCashTotal += s.reachCasinoBetCashTotal;
                     reachCasinowinCashTotal += s.reachCasinoWinCashTotal;
                     reachSlotBetCountTotal += s.reachSlotBetCountTotal;
                     reachSlotBetCashTotal += s.reachSlotBetCashTotal;
                     reachSlotwinCashTotal += s.reachSlotWinCashTotal;

                    honorCasinoBetCountTotal += s.honorCasinoBetCountTotal;
                    honorCasinoBetCashTotal += s.honorCasinoBetCashTotal;
                    honorCasinowinCashTotal += s.honorCasinoWinCashTotal;
                    honorSlotBetCountTotal += s.honorSlotBetCountTotal;
                    honorSlotBetCashTotal += s.honorSlotBetCashTotal;
                    honorSlotwinCashTotal += s.honorSlotWinCashTotal;
                })

                this.totalUser = totalUser;
                this.totalRecharge = totalRecharge;
                this.totalExchange = totalExchange;
                this.totalExchangePartner = totalExchangePartner;
                this.totalCasinoRecharge = totalCasinoRecharge;
                this.totalCasinoExchange = totalCasinoExchange;
                this.totalAccumulatePoints = totalAccumulatePoints;
                this.totalBetCount = totalBetCount;
                this.totalBetCancelAmount = totalBetCancelAmount;
                this.totalBetSpecialAmount = totalBetSpecialAmount;
                this.totalBetAmount = totalBetAmount;
                this.totalWinAmount = totalWinAmount;
                this.totalInplayWinAmount = totalInplayWinAmount;
                this.totalInplayBetCancelAmount = totalInplayBetCancelAmount;
                this.totalInplayBetAmount = totalInplayBetAmount;
                this.totalLBetCount = totalLBetCount;
                this.totalInplayBetCount = totalInplayBetCount;
                this.totalLBetAmount = totalLBetAmount;
                this.totalLWinAmount = totalLWinAmount;
                this.totalREProfit = this.totalRecharge - this.totalExchange - this.totalExchangePartner;
                this.totalCasinoREProfit = this.totalCasinoRecharge - this.totalCasinoExchange;
                this.totalBetProfit = this.totalBetAmount - this.totalWinAmount;
                this.totalPatnerStAmount = totalPatnerStAmount;
                this.totalWinPatnerStAmount = totalWinPatnerStAmount;
                this.totalPartnerBetStatisticAmount = totalPartnerBetStatisticAmount;
                this.totalPartnerBetWinStatisticAmount = totalPartnerBetWinStatisticAmount;
                this.totalPartnerLBetStatisticAmount = totalPartnerLBetStatisticAmount;
                this.totalPartnerLBetWinStatisticAmount = totalPartnerLBetWinStatisticAmount;
                this.totalLoginUserCount = totalLoginUserCount;
                this.totalAccumulateManagerAddCash = totalAccumulateManagerAddCash;
                this.totalAccumulateManagerAddPoint = totalAccumulateManagerAddPoint;
                this.totalAccumulateFirstRechargeBonusPoint = totalAccumulateFirstRechargeBonusPoint;
                this.totalAccumulateDayRechargeBonusPoint = totalAccumulateDayRechargeBonusPoint;
                this.totalAccumulateBetFailPoint = totalAccumulateBetFailPoint;
                this.totalAccumulateBetFail2RefPoint = totalAccumulateBetFail2RefPoint;
                this.totalLCancelAmount = totalLCancelAmount;
                this.totalLSpecialAmount = totalLSpecialAmount;

                this.casinoBetCountTotal = casinoBetCountTotal;
                this.casinoBetCashTotal = casinoBetCashTotal;
                this.casinowinCashTotal = casinowinCashTotal;
                this.casinocancelCashTotal = casinocancelCashTotal;
                this.slotBetCountTotal = slotBetCountTotal;
                this.slotBetCashTotal = slotBetCashTotal;
                this.slotwinCashTotal = slotwinCashTotal;
                this.slotcancelCashTotal = slotcancelCashTotal;
                this.fishRechargCashTotal = fishRechargCashTotal;
                this.fishExchangeCashTotal = fishExchangeCashTotal;

                this.reachCasinoBetCountTotal = reachCasinoBetCountTotal;
                this.reachCasinoBetCashTotal = reachCasinoBetCashTotal;
                this.reachCasinowinCashTotal = reachCasinowinCashTotal;
                this.reachSlotBetCountTotal = reachSlotBetCountTotal;
                this.reachSlotBetCashTotal = reachSlotBetCashTotal;
                this.reachSlotwinCashTotal = reachSlotwinCashTotal;


                this.honorCasinoBetCountTotal = honorCasinoBetCountTotal;
                this.honorCasinoBetCashTotal = honorCasinoBetCashTotal;
                this.honorCasinowinCashTotal = honorCasinowinCashTotal;
                this.honorSlotBetCountTotal = honorSlotBetCountTotal;
                this.honorSlotBetCashTotal = honorSlotBetCashTotal;
                this.honorSlotwinCashTotal = honorSlotwinCashTotal;
            },


        },
        created() {
            this.getMainStatistic();
        }
    }
</script>

<style scoped>
    .table50 {
        border: 2px solid #0c0e0e;
        margin-top: 5px;
        font-weight: 600;
    }

    .data {
        width: 100%;
        height: calc(100vh - 185px);
        overflow-y: scroll;
    }

</style>